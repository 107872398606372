import { targetsIMAS, targetsACPR } from "../lib/utils";

export default {
  id: "8",
  title:
    "Domaines requis d’expérience générale dans le secteur bancaire et financier",
  next: "9",
  previous: "6",
  defaultValues: {},
  sections: [
    {
      title: null,
      rows: [
        [
          {
            name: "evalMarcheBancaire",
            label: "Marchés bancaires ou financiers",
            type: "rating",
            options: { required: true },
            choices: [
              {
                value: "nonSignificative",
                label: "Non significative",
              },
              {
                value: "moyennementSignificative",
                label: "Moyennement significative",
              },
              {
                value: "significative",
                label: "Significative",
              },
            ],
          },
        ],
        [
          {
            name: "evalMarcheBancaireText",
            label: "Justification",
            type: "text",
          },
        ],
        [
          {
            name: "evalExigencesLegales",
            label:
              "Exigences juridiques et cadre réglementaire",
            type: "rating",
            options: { required: true },
            condition: targetsIMAS,
            choices: [
              {
                value: "nonSignificative",
                label: "Non significative",
              },
              {
                value: "moyennementSignificative",
                label: "Moyennement significative",
              },
              {
                value: "significative",
                label: "Significative",
              },
            ],
          },
          {
            name: "evalExigencesLegales",
            label:
              "Exigences légales et réglementaires applicables à un établissement assujetti (notamment dans le domaine de la lutte contre le blanchiment de capitaux et le financement du terrorisme) :",
            type: "rating",
            options: { required: true },
            condition: targetsACPR,
            choices: [
              {
                value: "nonSignificative",
                label: "Non significative",
              },
              {
                value: "peuSignificative",
                label: "Peu significative",
              },
              {
                value: "moyennementSignificative",
                label: "Moyennement significative",
              },
              {
                value: "significative",
                label: "Significative",
              },
            ],
          },
        ],
        [
          {
            name: "evalExigencesLegalesText",
            label: "Justification",
            type: "text",
          },
        ],
        [
          {
            name: "evalBlanchiment",
            condition: targetsIMAS,
            label:
              "Lutte contre le blanchiment de capitaux et le financement du terrorisme :",
            type: "rating",
            options: { required: true },
            choices: [
              {
                value: "nonSignificative",
                label: "Non significative",
              },
              {
                value: "peuSignificative",
                label: "Peu significative",
                condition: targetsACPR,
              },
              {
                value: "moyennementSignificative",
                label: "Moyennement significative",
              },
              {
                value: "significative",
                label: "Significative",
              },
            ],
          },
        ],
        [
          {
            name: "evalBlanchimentText",
            label: "Justification",
            type: "text",
            condition: targetsIMAS,
          },
        ],
        [
          {
            condition: targetsACPR,
            name: "evalPlanification",
            label:
              "Planification stratégique et compréhension de la stratégie commerciale ou du projet d’affaires (business plan) d’un établissement assujetti et de sa mise en œuvre",
            type: "rating",
            options: { required: true },
            choices: [
              {
                value: "nonSignificative",
                label: "Non significative",
              },
              {
                value: "peuSignificative",
                label: "Peu significative",
                condition: targetsACPR,
              },
              {
                value: "moyennementSignificative",
                label: "Moyennement significative",
              },
              {
                value: "significative",
                label: "Significative",
              },
            ],
          },
          {
            condition: targetsIMAS,
            name: "evalPlanification",
            label:
              "Planification stratégique, compréhension de la stratégie commerciale ou du plan d’activité d’un établissement de crédit et de sa mise en œuvre",
            type: "rating",
            options: { required: true },
            choices: [
              {
                value: "nonSignificative",
                label: "Non significative",
              },
              {
                value: "peuSignificative",
                label: "Peu significative",
                condition: targetsACPR,
              },
              {
                value: "moyennementSignificative",
                label: "Moyennement significative",
              },
              {
                value: "significative",
                label: "Significative",
              },
            ],
          },
        ],
        [
          {
            name: "evalPlanificationText",
            label: "Justification",
            type: "text",
          },
        ],
        [
          {
            condition: targetsACPR,
            name: "evalGestionRisques",
            label:
              "Gestion des risques (identification, évaluation, suivi, contrôle et atténuation des principaux types de risques d’un établissement assujetti, y compris le risque climatique)",
            type: "rating",
            options: { required: true },
            choices: [
              {
                value: "nonSignificative",
                label: "Non significative",
              },
              {
                value: "peuSignificative",
                label: "Peu significative",
                condition: targetsACPR,
              },
              {
                value: "moyennementSignificative",
                label: "Moyennement significative",
              },
              {
                value: "significative",
                label: "Significative",
              },
            ],
          },
          {
            condition: targetsIMAS,
            name: "evalGestionRisques",
            label:
              "Gestion des risques (détecter, évaluer, suivre, contrôler et atténuer les principaux types de risques auxquels un établissement de crédit est exposé)",
            type: "rating",
            options: { required: true },
            choices: [
              {
                value: "nonSignificative",
                label: "Non significative",
              },
              {
                value: "peuSignificative",
                label: "Peu significative",
                condition: targetsACPR,
              },
              {
                value: "moyennementSignificative",
                label: "Moyennement significative",
              },
              {
                value: "significative",
                label: "Significative",
              },
            ],
          },
        ],
        [
          {
            name: "evalGestionRisquesText",
            label: "Justification",
            type: "text",
          },
        ],
        [
          {
            name: "evalRisquesEnvironementaux",
            condition: targetsIMAS,
            label:
              "Connaissances et expérience en matière de risques liés au climat et à l’environnement",
            type: "rating",
            options: { required: true },
            choices: [
              {
                value: "nonSignificative",
                label: "Non significative",
              },
              {
                value: "peuSignificative",
                label: "Peu significative",
                condition: targetsACPR,
              },
              {
                value: "moyennementSignificative",
                label: "Moyennement significative",
              },
              {
                value: "significative",
                label: "Significative",
              },
            ],
          },
        ],
        [
          {
            name: "evalRisquesEnvironementauxText",
            label: "Justification",
            type: "text",
            condition: targetsIMAS,
          },
        ],
        [
          {
            name: "evalComptabiliteEtAudit",
            condition: targetsIMAS,
            label: "Comptabilité et Audit",
            type: "rating",
            options: { required: true },
            choices: [
              {
                value: "nonSignificative",
                label: "Non significative",
              },
              {
                value: "peuSignificative",
                label: "Peu significative",
                condition: targetsACPR,
              },
              {
                value: "moyennementSignificative",
                label: "Moyennement significative",
              },
              {
                value: "significative",
                label: "Significative",
              },
            ],
          },
        ],
        [
          {
            name: "evalComptabiliteEtAuditText",
            label: "Justification",
            type: "text",
            condition: targetsIMAS,
          },
        ],
        [
          {
            condition: targetsACPR,
            name: "evalEfficaciteDispositifs",
            label:
              "Évaluation de l’efficacité des dispositifs de gouvernance d’un établissement assujetti, mise en place de mécanismes de surveillance et de contrôle efficaces de celle-ci",
            type: "rating",
            options: { required: true },
            choices: [
              {
                value: "nonSignificative",
                label: "Non significative",
              },
              {
                value: "peuSignificative",
                label: "Peu significative",
                condition: targetsACPR,
              },
              {
                value: "moyennementSignificative",
                label: "Moyennement significative",
              },
              {
                value: "significative",
                label: "Significative",
              },
            ],
          },
          {
            condition: targetsIMAS,
            name: "evalEfficaciteDispositifs",
            label:
              "Évaluation de l’efficacité des dispositifs d’un établissement de crédit, garantissant une gouvernance, une surveillance et des contrôles efficaces",
            type: "rating",
            options: { required: true },
            choices: [
              {
                value: "nonSignificative",
                label: "Non significative",
              },
              {
                value: "peuSignificative",
                label: "Peu significative",
                condition: targetsACPR,
              },
              {
                value: "moyennementSignificative",
                label: "Moyennement significative",
              },
              {
                value: "significative",
                label: "Significative",
              },
            ],
          },
        ],
        [
          {
            name: "evalEfficaciteDispositifsText",
            label: "Justification",
            type: "text",
          },
        ],
        [
          {
            condition: targetsACPR,
            name: "evalInfoComptableFinanciere",
            label:
              "Interprétation des informations comptables et financières d’un établissement assujetti, identification des problèmes majeurs révélés par cette information et mise en place des mesures et contrôles appropriés.",
            type: "rating",
            options: { required: true },
            choices: [
              {
                value: "nonSignificative",
                label: "Non significative",
              },
              {
                value: "peuSignificative",
                label: "Peu significative",
                condition: targetsACPR,
              },
              {
                value: "moyennementSignificative",
                label: "Moyennement significative",
              },
              {
                value: "significative",
                label: "Significative",
              },
            ],
          },
          {
            condition: targetsIMAS,
            name: "evalInfoComptableFinanciere",
            label:
              "Interprétation des informations financières d’un établissement de crédit, identification des principaux problèmes sur la base de ces informations et contrôles et mesures appropriés",
            type: "rating",
            options: { required: true },
            choices: [
              {
                value: "nonSignificative",
                label: "Non significative",
              },
              {
                value: "peuSignificative",
                label: "Peu significative",
                condition: targetsACPR,
              },
              {
                value: "moyennementSignificative",
                label: "Moyennement significative",
              },
              {
                value: "significative",
                label: "Significative",
              },
            ],
          }
        ],
        [
          {
            name: "evalInfoComptableFinanciereText",
            label: "Justification",
            type: "text",
          },
        ],
        [
          {
            name: "autreExperienceSignificative",
            type: "textearea",
            label: "Autres expériences spécifiques (à détailler)",
            condition: targetsACPR,
          },
        ],
      ],
    },
  ],
};
